
import { defineComponent } from "vue";
import { useStore } from "vuex";
import BaseHeader2 from "@/components/BaseHeader2.vue";
import List from "@/components/comment/List.vue";
import Item from "@/components/comment/Item.vue";
import { MixedComment } from "@/components/comment/comment";
import LoadingIcon from "icons/Loading.vue";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";

export default defineComponent({
  components: {
    BaseHeader2,
    List,
    Item,
    InfiniteLoading,
    LoadingIcon,
  },
  data() {
    const store = useStore();
    return {
      store,
      memberId: null as null | string,
      infiniteId: +new Date(),
      page: 1,
      commentList: [] as Array<MixedComment>,
      category: "actor",
    };
  },
  computed: {
    member(): any {
      return this.store.getters["member/member"];
    },
    isLogin(): boolean {
      return this.store.getters["member/isLogin"];
    },
  },
  methods: {
    infiniteHandler($state: any) {
      this.load()
        .then((res: any) => {
          $state.loaded();
          if (this.commentList.length >= res.data.count) {
            $state.complete();
          }
          this.page += 1;
        })
        .catch(() => {
          $state.error();
        });
    },
    load() {
      return this.$axios
        .get("/urgent/mix/comment", {
          params: {
            member: this.memberId,
            page: this.page,
          },
        })
        .then((result) => {
          let res = result.data.results;
          if (res.length > 0) {
            if (this.page == 1) {
              this.commentList.splice(0, this.commentList.length);
              this.commentList.push(...res);
            } else {
              this.commentList.push(...res);
            }
          }
          return result;
        });
    },
    showCommentMenu(
      commentId: number,
      status: string,
      isAuthor: boolean,
      commentIndex: string | number,
      postType: string
    ) {
      let menu = [];
      if (isAuthor) {
        menu.push(
          ...[
            {
              display: "삭제",
              action: "showMixedCommentDeleteAlert",
              parameter: `${commentId},'${commentIndex}','${postType}'`,
              color: "nok",
            },
          ]
        );

        if (status == "공개") {
          menu.unshift({
            display: "비밀 댓글로 전환",
            action: "updateMixedCommentStatus",
            parameter: `${commentId},'비공개','${commentIndex}','${postType}'`,
            color: "ok",
          });
        } else {
          menu.unshift({
            display: "공개 댓글로 전환",
            action: "updateMixedCommentStatus",
            parameter: `${commentId},'공개','${commentIndex}','${postType}'`,
            color: "ok",
          });
        }
      } else {
        menu.push(
          ...[
            {
              display: "신고",
              action: "report",
              parameter: `${commentId},'${postType}'`,
              color: "nok",
            },
          ]
        );
      }

      this.$flutter.callHandler("bottomAlert", {
        actions: JSON.stringify(menu),
      });
    },
    showMixedCommentDeleteAlert(
      commentId: number,
      commentIndex: string,
      postType: string
    ) {
      this.$flutter.callHandler("alert", {
        title: "삭제하기를 누르시면 복구가 불가합니다.\n삭제하시겠습니까?",
        actions: JSON.stringify([
          {
            display: "삭제하기",
            action: "updateMixedCommentStatus",
            parameter: `${commentId},'삭제','${commentIndex}','${postType}'`,
          },
          {
            display: "닫기",
            action: "nok",
          },
        ]),
      });
    },
    updateMixedCommentStatus(
      commentId: number,
      status: string,
      commentIndex: string,
      postType: string
    ): void {
      let path = "/";
      if (postType == "boardpost") {
        path += "board/comment/" + commentId;
      } else if (postType == "urgent") {
        path += "urgent/comment/" + commentId;
      }
      if (status == "삭제") {
        this.$axios.delete(path).then(() => {
          this.commentList[Number(commentIndex)].status = status;
        });
      } else {
        this.$axios
          .patch(path, {
            status: status,
          })
          .then(() => {
            this.commentList[Number(commentIndex)].status = status;
          });
      }
    },
    report(contentId: number, contentType: string) {
      if (!this.isLogin) {
        this.$flutter.callHandler("showToast", {
          message: "로그인 사용자만 신고할 수 있습니다.",
        });
      }
      this.$router.push({
        path: "/report",
        query: { contentId, contentType },
      });
    },
    go(comment: MixedComment) {
      let path = "/";
      if (comment.post_type == "boardpost") {
        path += "talkroom/" + comment.post_id;
      } else if (comment.post_type == "urgent") {
        path += "urgent/" + comment.post_id;
      }
      this.$router.push(path);
    },
  },
  mounted() {
    if (this.$route.query) {
      this.memberId = (this.$route.query as { member: string }).member;
    }
  },
  beforeMount(): void {
    window.updateMixedCommentStatus = this.updateMixedCommentStatus;
    window.showMixedCommentDeleteAlert = this.showMixedCommentDeleteAlert;
    window.report = this.report;
  },
  beforeUnmount(): void {
    window.updateMixedCommentStatus = null;
    window.showMixedCommentDeleteAlert = null;
    window.report = null;
  },
});
